import React from "react"
import theme from "theme"
import { Theme, Text, Box, Image, Section } from "@quarkly/widgets"
import { Helmet } from "react-helmet"
import { GlobalQuarklyPageStyles } from "global-page-styles"
import { RawHtml, Override } from "@quarkly/components"
import * as Components from "components"
export default () => {
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"about"} />
      <Helmet>
        <title>Hakkımızda | Wash Oto Yıkama'da Hizmetler</title>
        <meta
          name={"description"}
          content={"Yıkamanızı Yükseltin, Sürüşünüzü Yükseltin"}
        />
        <meta
          property={"og:title"}
          content={"Hakkımızda | Wash Oto Yıkama'da Hizmetler"}
        />
        <meta
          property={"og:description"}
          content={"Yıkamanızı Yükseltin, Sürüşünüzü Yükseltin"}
        />
        <meta
          property={"og:image"}
          content={"https://wixerastand.com/img/1.jpg"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://wixerastand.com/img/6572572.png"}
          type={"image/x-icon"}
        />
        <link
          rel={"apple-touch-icon"}
          href={"https://wixerastand.com/img/6572572.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"76x76"}
          href={"https://wixerastand.com/img/6572572.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"152x152"}
          href={"https://wixerastand.com/img/6572572.png"}
        />
        <link
          rel={"apple-touch-startup-image"}
          href={"https://wixerastand.com/img/6572572.png"}
        />
        <meta
          name={"msapplication-TileImage"}
          content={"https://wixerastand.com/img/6572572.png"}
        />
      </Helmet>
      <Components.Header />
      <Section
        padding="65px 0 65px 0"
        sm-padding="60px 0 60px 0"
        quarkly-title="Images-15"
      >
        <Override slot="SectionContent" flex-wrap="wrap" flex-direction="row" />
        <Box
          sm-padding="0px 0px 0px 0px"
          padding="16px 16px 0px 16px"
          lg-order="1"
          width="50%"
          align-items="flex-start"
          lg-align-items="center"
          lg-margin="0px 0px 0px 0px"
          sm-margin="0px 0px 0px 0px"
          display="flex"
          flex-direction="column"
          lg-width="100%"
          justify-content="space-between"
        >
          <Box display="flex" flex-direction="column">
            <Text
              margin="0px 0px 36px 0px"
              color="--darkL2"
              font="--headline2"
              lg-text-align="center"
              sm-font="--headline3"
              sm-margin="0px 0px 20px 0px"
              lg-margin="0px 0px 18px 0px"
              md-text-align="left"
            >
              Hakkımızda
            </Text>
            <Text
              margin="0px 0px 0px 0px"
              color="--darkL2"
              font="--base"
              lg-text-align="center"
              md-text-align="left"
            >
              El değmemiş araçlara ve çevreyi korumaya olan tutkumuzun bizi en
              iyi self servis oto yıkama deneyimini yaratmaya ittiği Wash'in
              kalbine dalın. Sadece birkaç bölmeden oluşan mütevazı
              başlangıcımızdan otomobil meraklıları için bir sığınak haline
              gelene kadar, mükemmelliğe olan bağlılığımız hiç azalmadı. Sadece
              aracınızı temizleyen değil, aynı zamanda gelecek nesiller için
              çevreyi koruyan bir hizmet sunmaya inanıyoruz.
            </Text>
          </Box>
        </Box>
        <Box
          margin="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
          sm-padding="0px 0px 0px 0px"
          sm-margin="0px 0px 30px 0px"
          flex-direction="column"
          display="flex"
          width="50%"
          lg-width="100%"
        >
          <Box width="100%">
            <Image src="https://wixerastand.com/img/3.jpg" width="100%" />
          </Box>
        </Box>
      </Section>
      <Section
        padding="65px 0 65px 0"
        sm-padding="60px 0 60px 0"
        quarkly-title="Images-10"
      >
        <Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
        <Box
          sm-padding="0px 0px 0px 0px"
          sm-margin="0px 0px 30px 0px"
          flex-direction="column"
          display="flex"
          width="50%"
          lg-width="100%"
          margin="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
        >
          <Box
            overflow-x="hidden"
            overflow-y="hidden"
            transform="translateY(0px)"
            transition="transform 0.2s ease-in-out 0s"
            padding="0px 0px 70% 0px"
            width="100%"
            height="auto"
            position="relative"
            hover-transform="translateY(-10px)"
          >
            <Image
              left={0}
              src="https://wixerastand.com/img/4.jpg"
              object-fit="cover"
              width="100%"
              top="auto"
              right={0}
              bottom="0px"
              min-height="100%"
              position="absolute"
              display="block"
            />
          </Box>
        </Box>
        <Box
          flex-direction="column"
          lg-width="100%"
          lg-align-items="center"
          lg-margin="0px 0px 0px 0px"
          sm-margin="0px 0px 0px 0px"
          lg-order="1"
          width="50%"
          align-items="flex-start"
          sm-padding="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
          display="flex"
        >
          <Text
            margin="0px 0px 36px 0px"
            color="--darkL2"
            font="--headline2"
            lg-text-align="center"
            sm-font="--headline3"
            md-text-align="left"
          >
            Tesislerimiz
          </Text>
          <Text
            margin="0px 0px 0px 0px"
            color="--darkL2"
            font="--base"
            lg-text-align="center"
            md-text-align="left"
          >
            Wash'te tesislerimiz aracınızı yıkayabileceğiniz bir yerden çok daha
            fazlasıdır - kalite ve verimliliğe olan bağlılığımızın bir
            kanıtıdır. En son oto yıkama teknolojisiyle donatılmış olarak,
            aracınızın en iyi şekilde görünmesini sağlamak için bir dizi hizmet
            sunuyoruz. Yüksek basınçlı jetlerimiz, yumuşak dokunuşlu
            fırçalarımız ve birinci sınıf sabunlarımız, aracınızın cilasına veya
            çevreye zarar vermeden kapsamlı bir temizlik sağlamak için özenle
            seçilmiştir.
          </Text>
        </Box>
      </Section>
      <Section padding="90px 0 100px 0" quarkly-title="News/Streams/Blog-7">
        <Text
          margin="0px 0px 50px 0px"
          font="normal 600 42px/1.2 --fontFamily-sans"
        >
          Neden Öne Çıkıyoruz
        </Text>
        <Box
          min-width="100px"
          min-height="100px"
          display="grid"
          grid-template-columns="repeat(4, 1fr)"
          grid-gap="0 40px"
          md-grid-template-columns="1fr"
          md-grid-gap="50px 0"
        >
          <Box
            min-width="100px"
            min-height="100px"
            display="flex"
            margin="0px 0px 0 0px"
            md-flex-direction="column"
            flex-direction="column"
            justify-content="space-between"
            md-justify-content="flex-start"
          >
            <Box
              min-width="100px"
              min-height="100px"
              md-margin="0px 0px 30px 0px"
              margin="0px 0px 0 0px"
              display="flex"
              flex-direction="column"
              md-width="100%"
              md-justify-content="flex-start"
            >
              <Box min-width="100px" min-height="100px">
                <Text
                  margin="0px 0px 10px 0px"
                  font="normal 600 24px/1.2 --fontFamily-sans"
                  lg-margin="0px 0px 15px 0px"
                >
                  Çevre Dostu Uygulamalar
                </Text>
                <Text
                  margin="0px 0px 20px 0px"
                  font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
                  lg-margin="0px 0px 18px 0px"
                  color="#626970"
                  md-margin="0px 0px 25px 0px"
                >
                  Sürdürülebilirlik konusunda kararlıyız, su tasarrufu sağlayan
                  teknikler ve çevre dostu ürünler kullanıyoruz.
                </Text>
              </Box>
            </Box>
          </Box>
          <Box
            min-width="100px"
            min-height="100px"
            display="flex"
            margin="0px 0px 0 0px"
            md-flex-direction="column"
            flex-direction="column"
            justify-content="space-between"
            md-justify-content="flex-start"
          >
            <Box
              min-width="100px"
              min-height="100px"
              md-margin="0px 0px 30px 0px"
              margin="0px 0px 0 0px"
              display="flex"
              flex-direction="column"
              md-width="100%"
              md-justify-content="flex-start"
            >
              <Box min-width="100px" min-height="100px">
                <Text
                  margin="0px 0px 10px 0px"
                  font="normal 600 24px/1.2 --fontFamily-sans"
                  lg-margin="0px 0px 15px 0px"
                >
                  İleri Teknoloji
                </Text>
                <Text
                  margin="0px 0px 20px 0px"
                  font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
                  lg-margin="0px 0px 18px 0px"
                  color="#626970"
                  md-margin="0px 0px 25px 0px"
                >
                  Tesislerimiz, oto yıkama ekipmanlarındaki en son gelişmelere
                  sahiptir ve her seferinde üstün bir temizlik sağlar.
                </Text>
              </Box>
            </Box>
          </Box>
          <Box
            min-width="100px"
            min-height="100px"
            display="flex"
            margin="0px 0px 0 0px"
            md-flex-direction="column"
            flex-direction="column"
            justify-content="space-between"
            md-justify-content="flex-start"
          >
            <Box
              min-width="100px"
              min-height="100px"
              md-margin="0px 0px 30px 0px"
              margin="0px 0px 0 0px"
              display="flex"
              flex-direction="column"
              md-width="100%"
              md-justify-content="flex-start"
            >
              <Box min-width="100px" min-height="100px">
                <Text
                  margin="0px 0px 10px 0px"
                  font="normal 600 24px/1.2 --fontFamily-sans"
                  lg-margin="0px 0px 15px 0px"
                >
                  Detaylara Önem Veriyoruz
                </Text>
                <Text
                  margin="0px 0px 20px 0px"
                  font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
                  lg-margin="0px 0px 18px 0px"
                  color="#626970"
                  md-margin="0px 0px 25px 0px"
                >
                  Özenle seçilmiş temizlik ürünlerimizden titiz bakım
                  rutinlerimize kadar her ayrıntı, deneyiminizi geliştirmek için
                  tasarlanmıştır.
                </Text>
              </Box>
            </Box>
          </Box>
          <Box
            min-width="100px"
            min-height="100px"
            display="flex"
            margin="0px 0px 0 0px"
            md-flex-direction="column"
            flex-direction="column"
            justify-content="space-between"
            md-justify-content="flex-start"
          >
            <Box
              min-width="100px"
              min-height="100px"
              md-margin="0px 0px 30px 0px"
              margin="0px 0px 0 0px"
              display="flex"
              flex-direction="column"
              md-width="100%"
              md-justify-content="flex-start"
            >
              <Box min-width="100px" min-height="100px">
                <Text
                  margin="0px 0px 10px 0px"
                  font="normal 600 24px/1.2 --fontFamily-sans"
                  lg-margin="0px 0px 15px 0px"
                >
                  Topluluk Odaklılık
                </Text>
                <Text
                  margin="0px 0px 20px 0px"
                  font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
                  lg-margin="0px 0px 18px 0px"
                  color="#626970"
                  md-margin="0px 0px 25px 0px"
                >
                  Geri vermeye ve otomobil meraklıları arasında bir topluluk
                  duygusu geliştirmeye inanıyoruz.
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>
      </Section>
      <Section
        padding="65px 0 65px 0"
        sm-padding="60px 0 60px 0"
        quarkly-title="Images-11"
      >
        <Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
        <Box
          display="flex"
          width="50%"
          lg-width="100%"
          lg-align-items="center"
          sm-padding="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
          flex-direction="column"
          align-items="flex-start"
          lg-margin="0px 0px 0px 0px"
          sm-margin="0px 0px 0px 0px"
        >
          <Text
            sm-font="--headline3"
            margin="0px 0px 36px 0px"
            color="--darkL2"
            font="--headline2"
            lg-text-align="center"
            md-text-align="left"
          >
            Yolculuğumuza Katılın
          </Text>
          <Text
            margin="0px 0px 0px 0px"
            color="--darkL2"
            font="--base"
            lg-text-align="center"
            sm-margin="0px 0px 30px 0px"
            md-text-align="left"
          >
            Aracınızın yaşam yolculuğu benzersizdir, hikayeler ve maceralarla
            doludur. Wash'te sizi sadece kirleri temizlemeye değil, yeni
            hikayeler yaratmaya davet ediyoruz. Bu sadece aracınızın görünümünü
            korumakla ilgili değil, yaptığınız yolculuklara değer vermek ve
            henüz gelecek olanları dört gözle beklemekle ilgilidir. Aracınızın
            parlamasına, hikayelerini anlatmasına ve hayatınızın maceralarının
            bir uzantısı olmasına izin verin.
          </Text>
        </Box>
        <Box
          width="50%"
          lg-width="100%"
          margin="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
          sm-padding="0px 0px 0px 0px"
          sm-margin="0px 0px 30px 0px"
          flex-direction="column"
          display="flex"
        >
          <Box
            width="100%"
            height="auto"
            padding="0px 0px 70% 0px"
            transition="transform 0.2s ease-in-out 0s"
            hover-transform="translateY(-10px)"
            overflow-x="hidden"
            overflow-y="hidden"
            position="relative"
            transform="translateY(0px)"
          >
            <Image
              position="absolute"
              width="100%"
              top="auto"
              left={0}
              src="https://wixerastand.com/img/5.jpg"
              object-fit="cover"
              display="block"
              right={0}
              bottom="0px"
              min-height="100%"
            />
          </Box>
        </Box>
      </Section>
      <Components.Footer />
      <RawHtml>
        <style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  )
}
